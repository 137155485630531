import { EditOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import classNames from 'classnames';
import { groupBy, isNil, map } from 'lodash-es';
import { useNavigate } from 'react-router';
import { useBuildingConfig } from '../useBuildingConfig';
import { UsedShowWhatControlPanel } from './UsedShowWhatControlPanel';
import { useControlPanel } from './useControlPanel';
import { BlurComponent } from '../myInfo/BlurComponent';
import { ReadNavigation } from './ReadNavigation';

export default function CheckRead() {
	const navigate = useNavigate();
	const { changeTitle } = useBuildingConfig();
	changeTitle('核对水电用量');

	const { list, panel } = useControlPanel();
	const { controlPanel, isShowColdWater, isShowHotWater, isShowElectron } =
		UsedShowWhatControlPanel();

	// <div>快速核对当月水电</div>
	return (
		<>
			<ReadNavigation />
			<Card>
				{panel}
				{controlPanel}

				<div className="flex flex-col">
					{map(
						groupBy(
							list.toSorted((a, b) => {
								return (
									Number.parseInt(a.roomNumber, 10) -
									Number.parseInt(b.roomNumber, 10)
								);
							}),
							(v) => {
								return v.roomNumber.slice(0, v.roomNumber.length - 2);
							},
						),
						(rooms, key) => {
							console.log(rooms, key);

							return (
								<div className="flex flex-col mb-3">
									<div>{key}楼</div>
									<div className="flex flex-row gap-6">
										<div className="w-28 shrink-0">
											<div className="">房间号</div>
											{isShowColdWater && <div>冷水用量</div>}
											{isShowHotWater && <div>热水用量</div>}
											{isShowElectron && <div>电量用量</div>}
										</div>

										<div className="flex flex-row gap-8 overflow-x-auto x-scroll-beatify">
											{rooms.map((room) => {
												return (
													<div key={room.roomNumber}>
														<div className="flex flex-row">
															<div>{room.roomNumber}</div>
															<EditOutlined
																onClick={() => {
																	navigate(`/apartment/edit?uuid=${room.uuid}`);
																}}
															/>
														</div>
														{isShowColdWater && (
															<BlurComponent
																className={classNames({
																	'bg-black text-white':
																		room.totalColdWaterUsed.lt(0),
																	'font-bold text-red-500':
																		room.totalColdWaterUsed.eq(0),
																})}
															>
																{room.totalColdWaterUsed.toNumber()}
															</BlurComponent>
														)}
														{isShowHotWater && (
															<BlurComponent
																className={classNames({
																	'bg-black text-white':
																		room.totalHotWaterUsed.lt(0),
																	'font-bold text-yellow-500':
																		room.totalHotWaterUsed.eq(0),
																})}
															>
																{room.totalHotWaterUsed.toNumber()}
															</BlurComponent>
														)}
														{isShowElectron && (
															<BlurComponent
																className={classNames({
																	'bg-black text-white':
																		room.totalElectricUsed.lt(0),
																	'font-bold text-red-500':
																		room.totalElectricUsed.eq(0),
																})}
															>
																{room.totalElectricUsed.toNumber()}
															</BlurComponent>
														)}
													</div>
												);
											})}
										</div>
									</div>
								</div>
							);
						},
					)}
				</div>

				<div className="flex flex-col">
					{[
						{
							label: '用水未填写的房间号',
							filter: (i: (typeof list)[number]) =>
								isNil(i.coldWaterMeterReadingThisMonth),
						},
						{
							label: '用水量为0的房间号',
							filter: (i: (typeof list)[number]) => i.totalColdWaterUsed.eq(0),
						},

						{
							label: '用电未填写的房间号',
							filter: (i: (typeof list)[number]) =>
								isNil(i.electricMeterReadingThisMonth),
						},
						{
							label: '用电量为0的房间号',
							filter: (i: (typeof list)[number]) => i.totalElectricUsed.eq(0),
						},
					].map((k) => {
						return (
							<div className="my-2 flex flex-row flex-wrap" key={k.label}>
								<div> {k.label}：</div>
								{list.filter(k.filter).map((a) => (
									<div className="mx-1" key={a.uuid}>
										{a.roomNumber},
									</div>
								))}
							</div>
						);
					})}
				</div>
			</Card>
		</>
	);
}
